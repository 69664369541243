<dx-scroll-view
    class="calendar-background"
    (onPullDown)="onScrollViewPullDown($event)">
    <app-activity-calendar [studentID]='studentID'
        [(activityID)]='activityID'
        [(selectedDate)]='selectedDate'
        [shouldReload]='shouldReloadCalendar'>
    </app-activity-calendar>
    <div>
        <div class="calendar-background text-right in-calendar dx-theme-accent-as-text-color">
            <span *ngIf="selectedAttendanceModel">{{'TXT_AVAILABLE_AMENDS' | translate}}: {{selectedAttendanceModel.numberOfExcuses}}</span>
        </div>
        <div *ngIf="!!selectedAttendanceModel && !!selectedAttendanceModel.scheduleNotes" class="daypart top-border notes-wrapper">
          <h5>{{'TXT_NOTES' | translate}}</h5>
          <div class="float-right-button">
            <dx-button icon="spindown"
                       type="normal"
                       (onClick)="onNoteDetail($event, selectedAttendanceModel.scheduleNotes)">
            </dx-button>
          </div>
          <div class="attendance-notes attendance-notes-max-lines">{{selectedAttendanceModel.scheduleNotes}}</div>
        </div>
        <div class="daypart top-border">
            <h5 *ngIf="!!singlePeriodProgramOnly">{{'TXT_ATTENDANCE' | translate}}</h5>
            <h5 *ngIf="!singlePeriodProgramOnly">{{'TXT_MORNING' | translate}}</h5>
            <div class="full-width closed"  *ngIf="!selectedAttendanceModel || !selectedAttendanceModel.firstPeriodActive">{{'TXT_CLOSED' | translate}}</div>
            <table *ngIf="selectedAttendanceModel && selectedAttendanceModel.firstPeriodActive" class="full-width">
                <tr class="row">
                    <td class="switch-width" rowspan="2">
                         <dx-switch
                            [(value)]="selectedAttendanceModel.firstPeriodAttending"
                            [disabled]="!selectedAttendanceModel.firstPeriodClientEditable"
                            ></dx-switch>
                    </td>
                    <td rowspan="2">
                        <span *ngIf="!selectedAttendanceModel.firstPeriodWaitQueuePosition">
                            {{selectedAttendanceModel.firstPeriodAttendanceStatus | activityAttendanceStatus}}
                        </span>
                        <span *ngIf="!!selectedAttendanceModel.firstPeriodWaitQueuePosition">
                            {{('TXT_IN_A_WAIT_QUEUE' | translate).f(selectedAttendanceModel.firstPeriodWaitQueuePosition)}}
                        </span>
                        &nbsp;
                    </td>
                    <td class="right-column right-column-first">
                        <span *ngIf="selectedAttendanceModel.showFirstPeriodFreeSpots">{{'TXT_FREE_PLACES' | translate}}: {{selectedAttendanceModel.firstPeriodFreeSpots}}</span>
                    </td>
                </tr>
                <tr class="row">
                    <td class="right-column right-column-last">
                        <span *ngIf="selectedAttendanceModel.shouldShowFirstPeriodTypeIDEdit">
                        {{selectedAttendanceModel.firstPeriodAttendanceType | courseAttendanceType}}
                        </span>
                        &nbsp;
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="!singlePeriodProgramOnly" class="daypart top-border bottom-border">
            <h5>{{'TXT_AFTERNOON' | translate}}</h5>
            <div class="full-width closed"  *ngIf="!selectedAttendanceModel || !selectedAttendanceModel.secondPeriodActive">{{'TXT_CLOSED' | translate}}</div>
            <table *ngIf="selectedAttendanceModel && selectedAttendanceModel.secondPeriodActive" class="full-width">
                <tr class="row">
                    <td class="switch-width" rowspan="2">
                        <dx-switch
                            [(value)]="selectedAttendanceModel.secondPeriodAttending"
                            [disabled]="!selectedAttendanceModel.secondPeriodClientEditable"
                            ></dx-switch>
                    </td>
                    <td rowspan="2">
                        <span *ngIf="!selectedAttendanceModel.secondPeriodWaitQueuePosition">
                            {{selectedAttendanceModel.secondPeriodAttendanceStatus | activityAttendanceStatus}}
                        </span>
                        <span *ngIf="!!selectedAttendanceModel.secondPeriodWaitQueuePosition">
                                {{('TXT_IN_A_WAIT_QUEUE' | translate).f(selectedAttendanceModel.secondPeriodWaitQueuePosition)}}
                        </span>
                        &nbsp;
                    </td>
                    <td class="right-column right-column-first">
                        <span *ngIf="selectedAttendanceModel.showSecondPeriodFreeSpots">{{'TXT_FREE_PLACES' | translate}}: {{selectedAttendanceModel.secondPeriodFreeSpots}}</span>
                    </td>
                </tr>
                <tr class="row">
                    <td class="right-column right-column-last">
                        <span *ngIf="selectedAttendanceModel.shouldShowSecondPeriodTypeIDEdit">
                        {{selectedAttendanceModel.secondPeriodAttendanceType | courseAttendanceType}}
                        </span>
                        &nbsp;
                    </td>
                </tr>
            </table>
        </div>
        <div class="daypart" *ngIf="selectedAttendanceModel">
          <h5>{{'TXT_NOTES' | translate}}</h5>
          <dx-text-area
            valueChangeEvent="keyup"
            [minHeight]="60"
            [maxHeight]="100"
            [disabled]="!selectedAttendanceModel.secondPeriodClientEditable && !selectedAttendanceModel.firstPeriodClientEditable"
            placeholder="{{ (selectedAttendanceModel.secondPeriodClientEditable || selectedAttendanceModel.firstPeriodClientEditable)? ('TXT_ENTER_NOTE'| translate) : ''}}"
            [(value)]="selectedAttendanceModel.notes">
          </dx-text-area>
        </div>
        <div class="wb-button-margin text-center">
            <dx-button text="{{'TXT_SAVE' | translate}}"
                icon="floppy"
                type="success"
                [disabled]="!selectedAttendanceModel || !selectedAttendanceModel.isDirty"
                (onClick)="onAttendanceSave($event, false)">
            </dx-button>
        </div>
    </div>
</dx-scroll-view>
<dx-popup showTitle="true"
  title="{{'TXT_CONFIRMATION' | translate}}"
  fullScreen="true"
  showCloseButton="true"
  [(visible)]="newOneOffConfirmVisible">
  <div *dxTemplate="let data of 'content'">
    <p class="message-box-message">{{'TXT_NEW_ONE_OFF_CONFIRMATION' | translate}}</p>
    <div class="row"
        style="margin-top: 30px; margin-bottom: 10px;">
        <div class="col-xs-6 text-right">
        <dx-button text="{{'TXT_NO' | translate}}"
            class="wb-button"
            type="normal"
            (onClick)="onNoClickNewOneOffConfirmation($event)">
        </dx-button>
        </div>
        <div class="col-xs-6 text-left">
        <dx-button text="{{'TXT_YES' | translate}}"
            class="wb-button"
            type="success"
            (onClick)="onAttendanceSave($event, true)">
        </dx-button>
        </div>
    </div>
  </div>
</dx-popup>
<dx-popup showTitle="true"
  title="{{'TXT_CONFIRMATION' | translate}}"
  fullScreen="true"
  showCloseButton="true"
  [(visible)]="newWaitQueueConfirmVisible">
  <div *dxTemplate="let data of 'content'">
    <p class="message-box-message">{{'TXT_NEW_WAIT_QUEUE_CONFIRMATION' | translate}}
        <span *ngIf="selectedAttendanceModel && selectedAttendanceModel.isOneOffAttendanceEnabled">{{'TXT_NEW_WAIT_QUEUE_CONFIRMATION_ONE_OFF' | translate}}</span> </p>
    <div class="row"
        style="margin-top: 30px; margin-bottom: 10px;">
        <div class="col-xs-6 text-right">
        <dx-button text="{{'TXT_NO' | translate}}"
            class="wb-button"
            type="normal"
            (onClick)="onNoNewWaitQueueConfirmation($event)">
        </dx-button>
        </div>
        <div class="col-xs-6 text-left">
        <dx-button text="{{'TXT_YES' | translate}}"
            class="wb-button"
            type="success"
            (onClick)="onAttendanceSave($event, true)">
        </dx-button>
        </div>
    </div>
  </div>
</dx-popup>
<dx-popup showTitle="true"
          title="{{'TXT_NOTES' | translate}}"
          fullScreen="true"
          showCloseButton="true"
          [(visible)]="notesVisible">
  <div *dxTemplate="let data of 'content'" class="attendance-notes">
    {{scheduleNotes}}
  </div>
</dx-popup>

<dx-load-panel
    [(visible)]="loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    closeOnOutsideClick="false">
</dx-load-panel>
<dx-load-panel
    [(visible)]="saving"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    message="{{'TXT_SAVING' | translate}}"
    closeOnOutsideClick="false">
</dx-load-panel>
